import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "joelHayhurst",
  "categories": ["linux", "config"],
  "date": "2021-05-18",
  "path": "/blog/wsl-for-rails-devs",
  "summary": "We describe our experience developing Rails on WSL",
  "title": "WSL for Rails Developers",
  "image": "./wsl-microsoft-store.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "WSL for Rails developers",
      "description": "We describe our experience developing Rails on WSL"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`It is now possible, thankfully, to develop in Rails on Windows. For many years, it seemed one needed a Mac for this purpose; and I have nothing against Macs, and enjoy the MacBook Pro, which I have used throughout my career. But I also have a Windows machine. I like to play games sometimes, and now that I work from home, along with an increasing number of people, I have access to it. I like the idea that developers should be able to enjoy their preference in environment, where reasonable. Some prefer to boot directly into Linux, which is also a perfectly fine choice. On a whim, I decided to try Rails development on Windows, and it has worked out nicely.`}</p>
    <p>{`With WSL, the Windows Subsystem for Linux, we can now run a Linux distribution `}<em parentName="p">{`within`}</em>{` Windows 10. You can use it similarly to how you might use Homebrew on a Mac, and the whole thing is surprisingly seamless, especially after some recent improvements. All of the Linux development and server tools you're used to are present, and work the same as you are familiar with.`}</p>
    <h3>{`Installation`}</h3>
    <p>{`It's relatively easy to install; first you have to enable WSL as a "Windows feature", and then you go to the Microsoft Store and install your preferred distro. I know they have Debian and Ubuntu available, as well as some others. I chose Debian.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "404px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "62.576687116564415%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACJklEQVQoz3WS627bIBiGff+XsguYummatrbLwXGaLqmPYMAG20DiA47jU5xkIv3TH92jl0/AJwR6eQ3fcwmJ4jgmmCCIpNwPw9i2Xdf1H3U8NqdTW9dHzkXfD3lexJQZeb5XValUWauqyPP21Fwv03QetabzNJ3Po2YYhnEcu65TSull3wkhjJDmXpi9BfwNcIDlzkt3fuKCFBIBiXBASqis66pSqlKaStVto74sooe5bURxSpMsgAQiGjOOoySANKYZIky3GE9SfjgcsizjnOsihBQcMeEBbJjzxWa9WVsvy9nMWpqvm41prl4sa7Vcrpfm9vXVXJor05o9Pf/+9bheWZa5enp89lztlEEpI6GbpXFEYoQwo4xSihHCGBNCIICMsTRN0yRJWMI519P7DoChcWqnMvmZJ6br4TAMCcKu7SCEeJalWZYk2eVyuX3ger3ebre6rj3PN+pmkPihyqwAxBjjOCK+50m5196e2nEcbp9xHkcIQ0PVfQa/VnwdBBEhhEYxBAAEQEopOC+K4tPDTdN4vm807VTS70Wy8IMIBj6EIfADGMDbf3h/thDi73Zn1MdTwe1qj7jItRtpqq8Usuu6tm27tu37rr9HpOs1wzBcLpeyLH0/MKqqLEv99Uqp+s57GIo7h7x4tJMfy7dvs+0fhzmI2rvtfDb3fB8AaEi5F4ILwTnX4161dBaE5EK+BGzlINMON4BBwkIIHdvBGDuO+w8aTsIOt6sgiAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Enabling the WSL Windows feature",
        "title": "Enabling the WSL Windows feature",
        "src": "/static/01a94ce590d03e7fd06a7d472a7a841f/494f9/wsl-features.png",
        "srcSet": ["/static/01a94ce590d03e7fd06a7d472a7a841f/222b7/wsl-features.png 163w", "/static/01a94ce590d03e7fd06a7d472a7a841f/ff46a/wsl-features.png 325w", "/static/01a94ce590d03e7fd06a7d472a7a841f/494f9/wsl-features.png 404w"],
        "sizes": "(max-width: 404px) 100vw, 404px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.39877300613497%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABfUlEQVQoz4XQz0sbQRQH8BG8ePIPiGIJZn/M7sybmc1kNzszu+NuRBG7obS1QqkKCmLrodBDq7EFD977x5RQz/oPeBE8xIMH8c8oCQYMFPLhe3iH93iPh6SU7XZCGfd87Pt+MILxc+15HsbYdV3HcdwxZ8R1XeT7HmGRANISwBgXgidJAgCcc6UU51xKiTH2/gcRQoCSV9U3f+V9bpTKrDZZnttmMxLTIEIpkKD+rheWW2umVVpT5Gq1U0gpo2kQpYQQgpPC0a8b6WYj3azrrhepSDAhBDyj40xAlJCAwtxRH/UGqHc3c3KLeoNa9+TT9tudvf2tD9vAWEiAAhtm0nA4JDD3uY9OB/bg7/KXa/TzoVb9aMaG2Qr0OrQ0JEWYrgXxSgh82D6GRlfD7PHl/Nebp/ru7/YFOn9crL6XWpadUjajJI7LokjiFmdMvHgi53y8+aiPzu7bH/8sHV6hXw8L3dM3Gx1blFobk2XGZFqbPLfWWmOMtVZprdL0H3Oie7K12eFJAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Debian on the Microsoft Store",
        "title": "Debian on the Microsoft Store",
        "src": "/static/2721cc4426397ceddd210396f62a9514/a6d36/wsl-microsoft-store.png",
        "srcSet": ["/static/2721cc4426397ceddd210396f62a9514/222b7/wsl-microsoft-store.png 163w", "/static/2721cc4426397ceddd210396f62a9514/ff46a/wsl-microsoft-store.png 325w", "/static/2721cc4426397ceddd210396f62a9514/a6d36/wsl-microsoft-store.png 650w", "/static/2721cc4426397ceddd210396f62a9514/1fbe8/wsl-microsoft-store.png 757w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Terminals`}</h3>
    <p>{`As for terminals, there's a few options. The default terminal that comes with WSL is named after the distribution you installed, so for me it is simply called "Debian". It has the Debian swirl logo, which I like, and the default color scheme and font it comes with I find pleasant. On the downside, I've found it will always scroll down if new content comes up (say you are tailing a test log), so I got in the habit of waiting for the tests to finish before examining logs. It also doesn't have tabbing, but I found I don't need tabbing as much as I thought; I'm not on a laptop screen, so I have enough space to keep separate terminals open. It is possible to use PowerShell to access WSL, by running the `}<inlineCode parentName="p">{`wsl`}</inlineCode>{` command, but I find the color scheme is a bit off. And there's other options, like the new Windows Terminal, which does have tabbing and lots of customization options, but requires editing a text file to change settings (you can do it, you're a developer!), and still feels kind of in beta. In general though, I'm fine with the default WSL terminal.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "494px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "33.74233128834356%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAeklEQVQY06XOwQrCMBAE0Bw2uNvZphskNSBI0qY/4DH//2GiPSulPua2DLPumddl2+qjKEBE/gsiYmbvfYwRgKqmlNx1mWy1+Z5vc2a+/MCfs4gws4iUUtwwAWHQUc0shDAe1nt3Ct2zP3MQgPeyngKg1nq+/Ndya+0FfokVUMAmsJUAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Default Debian WSL terminal",
        "title": "Default Debian WSL terminal",
        "src": "/static/a2569589606893caa4307c1e9b736f0f/d72d4/wsl-terminal3.png",
        "srcSet": ["/static/a2569589606893caa4307c1e9b736f0f/222b7/wsl-terminal3.png 163w", "/static/a2569589606893caa4307c1e9b736f0f/ff46a/wsl-terminal3.png 325w", "/static/a2569589606893caa4307c1e9b736f0f/d72d4/wsl-terminal3.png 494w"],
        "sizes": "(max-width: 494px) 100vw, 494px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`VSCode`}</h3>
    <p>{`WSL also integrates perfectly with VSCode, which I use for most of my code editing. You can edit files directly within WSL and have them immediately affect the app. I mention this because there used to be an issue with this in WSL 1, but it has been resolved since WSL 2.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "545px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "27.607361963190186%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABR0lEQVQY0z2NXUvCUACG9zvS3EZnm+XOxz7OnLpcbLXNnbOtqBspgrrwwgsriQKFROhCiAghkSiwnxpa9PLw3D28gqppGCNuOzlLszzvJJ0sy5IkbrgNhBDejBCi67ooirIsS7KsqIqiKpqmCZRSCHVG63maMsYyznnKoijyfZ9S+t//xaJYqaoVm+DanlOtClCHGCFmO0XGGedFURyGoeu6nudZpoURJpgQTCCEkiStnyVJAQAAZQcAwTCJ23DPTk8Y61Bq113Hti1MECaIGNgwiWES0zIggqXtUrlc2gJymdR2qwpRgeC3Ap4c93uDy/ProH0U+FF4ECchS0IWB2kcpmsHLGxHJqQ2cgxct5z9ptXyzKYwf/h8H33N75dvw4/FaLUcfy/Gq6f+y+PV86g3mw5ex73Z8GI6vJjedie/3G180538AKqIXEeHG23CAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Debian WSL in VSCode",
        "title": "Debian WSL in VSCode",
        "src": "/static/b7ec1ff558e9e80d189d5ac23b2587ef/3ddad/wsl-vscode.png",
        "srcSet": ["/static/b7ec1ff558e9e80d189d5ac23b2587ef/222b7/wsl-vscode.png 163w", "/static/b7ec1ff558e9e80d189d5ac23b2587ef/ff46a/wsl-vscode.png 325w", "/static/b7ec1ff558e9e80d189d5ac23b2587ef/3ddad/wsl-vscode.png 545w"],
        "sizes": "(max-width: 545px) 100vw, 545px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Docker`}</h3>
    <p>{`Docker Desktop also integrates very nicely with WSL since version 2. Before WSL 2, Docker had to use Hyper-V to emulate Linux, but now we don't even need Hyper-V enabled. Docker now uses the WSL 2 backend, and it seems quite a bit faster to start as well, in my experience.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "357px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "35.58282208588957%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABYklEQVQY0z3LSU7jQACFYe9ZIbV6iZTEcmpwTa4qV6UGG48k6gAhqFuIEOhecAEugMQx2HAdLsBlWKGoJZ6+5fuT+aLB1ZKRnIvDtNKMMjAHEEAEEIQYAvQNAURRLnPquFwQnqDto9g/tVa2XVfHaugGv/DpNAUZyNIsS+cwgznE/xGIOc41YbUy7hDPJmCWTpEC0wnFtI51UzXRxejC0PRn3ei0JQBzTBjMGcAC5hpTmzNPRUIwO354+/H8cVLtHSO7m7v9zW57vvm9uf57e/9vd/9nc22FckXppQmyjLKMQgVaBFoklLujp/fZ6+fPyxeP5mMzDlXbx2asu7FuW1etmmF12ve+vhhWF/1y3YyjqwJTkcnEEg5P7ybbZyl8EPry7NfVcr3ul0EaX5RBGie049pz7ZhyVHomPVOB68DLxHLjCfMQOSodLw+/QkdlorLfgrShsLEwUZgoyijKcGC+AKVFXgxk+UG9AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Booting Docker Desktop on WSL 2",
        "title": "Booting Docker Desktop on WSL 2",
        "src": "/static/c22bef4097273d285ea7dc197d847246/22d0a/wsl-docker.png",
        "srcSet": ["/static/c22bef4097273d285ea7dc197d847246/222b7/wsl-docker.png 163w", "/static/c22bef4097273d285ea7dc197d847246/ff46a/wsl-docker.png 325w", "/static/c22bef4097273d285ea7dc197d847246/22d0a/wsl-docker.png 357w"],
        "sizes": "(max-width: 357px) 100vw, 357px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Filesystem`}</h3>
    <p>{`When you use WSL, you will want to store all of your relevant code within the WSL filesystem, not on the Windows filesystem. In WSL 1 there was more crossover, but not in WSL 2; everything goes on the Linux virtual drive. It is still possible to browse these files using Windows Explorer, if you are so inclined, but in general probably best to stick with Linux tools when using this drive. Still I do open one of my WSL directories in Explorer regularly for the purpose of easily opening screenshots produced by Capybara.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "549px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "36.19631901840491%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIklEQVQY012NSW7CMABFcwZGCcdTcDzGJokdJ3HJAjotuuoNUDel979AVVAR4i3+6j+97PMjPqXBe8/LsqoqpVSMkTFW1zXnXEppjBFS6gv5BQA2AAAIYeYbtd0WhFDGOCYYY1wUBaWU/IMxLstSCCGlRAjmeX49AAAyguliuZSCvRwGKdX1fVtCCELIuV13QRsNITTGtG3LGMvW69V8vopefp+etbJ3SXKTrbX90IfgY4wQ5krplNLO2Yzzcr5Yh1acT29NEyilt+Zd2fVD34Uu+PAna73fTzvnMkrIbLYMjTifXjlXUgrG2GPZuXEcuq7z3iMIldLjmKytsoLSDYCptz9f7ylNIXit1H0cIVQ3zTRN034axxHmwFTV8XBsm/oXv4hC3gV0Wq4AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Viewing WSL files in Explorer",
        "title": "Viewing WSL files in Explorer",
        "src": "/static/1348a707d563e5d1ee4c5991973d8286/928ea/wsl-explorer.png",
        "srcSet": ["/static/1348a707d563e5d1ee4c5991973d8286/222b7/wsl-explorer.png 163w", "/static/1348a707d563e5d1ee4c5991973d8286/ff46a/wsl-explorer.png 325w", "/static/1348a707d563e5d1ee4c5991973d8286/928ea/wsl-explorer.png 549w"],
        "sizes": "(max-width: 549px) 100vw, 549px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Copy-and-paste`}</h3>
    <p>{`One other note about transitioning from Mac, you'll probably want to enable the Ctrl-Shift-C and Ctrl-Shift-V copy-paste functionality in the terminal. On Mac, we have that handy Command key, but on Windows we are stuck with just Ctrl, which is used plenty by Linux itself; so Ctrl-Shift is a necessary compromise. It's not that hard to get accustomed to, and besides that, we can also use right-click to copy and paste text.`}</p>
    <h3>{`Caveats`}</h3>
    <p>{`I've been developing with this system for over a year now and haven't run into any serious problems. Probably the biggest issue I had is that WSL, combined with the code repositories of some large apps I'm working on, used up a lot of disk space, and my C: drive only has 256 GB. I did however find a way to move WSL to a separate drive, a nice 1 TB SSD, and that solved my space issues. This can be accomplished using the `}<inlineCode parentName="p">{`wsl --export`}</inlineCode>{` and `}<inlineCode parentName="p">{`wsl --import`}</inlineCode>{` commands without much fuss.`}</p>
    <p>{`Another issue I've faced is that when working on a larger application, a lot of memory is used up by WSL. Running two operating systems simultaneously uses more resources. Even after quitting the applications, and quitting Docker, this memory would still be reserved by WSL. The solution is that when I'm done with using WSL for the day, I run `}<inlineCode parentName="p">{`wsl --shutdown`}</inlineCode>{` in PowerShell and that frees up all the memory again. I went ahead and upgraded to 32 GB of RAM to avoid these paging annoyances. However, this upgrade is only necessary because of the size of this application. Every other application I've worked in was comfortable with 16 GB.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "28.22085889570552%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA9UlEQVQY023LX2uCUBjH8ed4zPmPpLQihdTlmTUjWqjDWDe7XBvRZnFsIxPU9/8SxllttDH48L15nh/0TbPX61qmpSgKAEKIYzgMrCeY4xsY8z8kSRaEK/bvLgo7OQ6SXAtSZfTK6m9EstaCVPY36u2bRNYweEL26sx55pwVOC8GWUJzVsiTgzrN9ag2oqoTs+phaYSVHlZGXHfiSpwcwKOIZN8okI8WeYRuVFpJ3b8vW/OdHOy16bs4znifnjR8KowovqHI213Ygpcp9gK0Wd6eF+27ozRmB0xYYbi99Ht5HqvOA4CbwnXKOvya/f37Fxs33eUnifU2F0WIHEIAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Shutting down WSL with PowerShell",
        "title": "Shutting down WSL with PowerShell",
        "src": "/static/49110ef872ccda4fd8a33ee8052f9b3d/a6d36/wsl-powershell.png",
        "srcSet": ["/static/49110ef872ccda4fd8a33ee8052f9b3d/222b7/wsl-powershell.png 163w", "/static/49110ef872ccda4fd8a33ee8052f9b3d/ff46a/wsl-powershell.png 325w", "/static/49110ef872ccda4fd8a33ee8052f9b3d/a6d36/wsl-powershell.png 650w", "/static/49110ef872ccda4fd8a33ee8052f9b3d/889a4/wsl-powershell.png 658w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Conclusion`}</h3>
    <p>{`These are all of the thoughts I have on WSL at this time. I hope you find it useful to be able to program in Rails on Windows, if that is your desire. If you give it a shot, you might find it's more comfortable than you expected. Thanks for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      